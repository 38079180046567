<template>
    <div>

      <rxNavBar v-if="androidFlg == false" title="试岗任务" androidOrIOSFlag="true"></rxNavBar>
      <rxNavBar v-else title="试岗任务"></rxNavBar>
      <div class="auditDiv">
        <van-pull-refresh v-model="refreshing"  @refresh="onRefresh"   >
          <van-list v-model="loading" :finished="finished" finished-text="" @load="this.onLoad">
            <div class="stateList" v-for="(item,index) in orders" :key="index">
              <div>
                <!--                循环列表头-->
<!--             0待提交，1待上级审， 2待上上级审，3已审批，4拒绝入职，5重新预约考试，6预约待确认，7成绩待上传，8考试未通过，9入职，10重新学习，11开单入职-->
                <div
                    :class="'top-title top-title-' + item.status">
                  {{ item.quizStatus=='4'?item.quizStatusStr:item.statusStr!=''?item.statusStr:'待提交' }}
                </div>
                <div class="headInfo" >
<!--                  <span class="headSpan">{{item.planName}}</span>-->
<!--                  <div class="dot"></div>-->
                  {{item.nameStr}}
                </div>
                <div class="rule3"></div>
                <div class="btnArray">
                  <button class="btn2" @click="completeView(item)">查看</button>
                  <button class="btn1" @click="completeTask(item)" v-if="item.status==='0'">学习</button>
                  <button class="btn1" @click="completeTask(item)" v-if="item.status==='10'">重新学习</button>
                  <button class="btn1" @click="appointmentExam(item)" v-if="(item.status==='3'||item.status==='5'||item.status==='8')&&!orderPlacedTime">预约考试</button>
                  <button class="btn1" @click="orderEntry(item)" v-if="(item.status==='3'||item.status==='5'||item.status==='8')&&orderPlacedTime">开单入职</button>

                  <button class="btn1" @click="appointmentExam(item)" v-if="item.status==='6'">修改预约时间</button>
                  <!--                  <button class="btn1" @click="appointmentExam(item)" v-if="item.status==='8'">预约考试</button>-->


                </div>
              </div>
            </div>

            <!--空状态显示-->
            <common-empty v-if="orders.length===0"></common-empty>
          </van-list>
        </van-pull-refresh>
      </div>

      <van-popup v-model="appointmentShow" position="bottom" :style="{ height: '50%' }" >
        <img style="float: left;margin: 20px 5px 0 15px;width: 15px;height: 15px;" src="../../../assets/images/colon.png">
        <div style="margin-top: 18px;font-size: 15px;font-weight: bold">预约考试</div>
        <div class="date-dropDownList">
          <div class="part-inputpart-row" @click="isAppointmentTimeShow = !isAppointmentTimeShow">
            <span class="maker" :class="{'orangeColor':(appointmentTime!=undefined && appointmentTime!='')}">• </span>
            <span class="part-inputpart-row-enablenon part-inputpart-row-header">预约时间</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="appointmentTime ? 'part-inputpart-row-normaltext':'' ">{{appointmentTimeStr}}</span>
            <img class="part-inputpart-row-right" :class=" isAppointmentTimeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
          </div>
          <div class="notice">
            <span style="font-weight: bold">温馨提示：</span>预约考试的最优时间为：{{item.addTime}}，试岗期有请假的直接顺延请假天数。只要手续齐全，考试及面试通关后，即可直接办理入职手续。若提前预约考试时间，考试通关和入职办理将会来总部两趟，如有不明白事宜，提前致电人力资源部人事专员确认。
          </div>
          <div class="sub_btn">
            <van-button  :class="'saveButton_Enable'" size="large" @click="saveAppointmentTime()">保存
            </van-button>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="isAppointmentTimeShow" position="bottom" :style="{ height: '30%' }" >
        <div class="date-dropDownList">
          <van-datetime-picker v-model="appointmentTime" type="datetime" title="选择年月日" item-height="35px"
                               @confirm="appointmentExamConfirm" @cancel="isAppointmentTimeShow=false"/>
        </div>
      </van-popup>
    </div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {
  Button,
  DatetimePicker, Dialog,
  Divider,
  DropdownItem,
  DropdownMenu,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search
} from "vant";
import {checkAndroid, checkIOS, getStaffId, responseUtil} from "../../../libs/rongxunUtil";
import {
  mainTrialPlanRecordPage,
  startProbation,
  saveOrUpdateAppointQuiz,
  removeAfterRentComplaint
} from '../../../getData/getData'
import util from '../../contract/util'
export default {
  name: "probationAssignment",
  components:{
    [Button.name]: Button,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]:PullRefresh,
    [DatetimePicker.name]: DatetimePicker,
  },
  data(){
    return{
      androidFlg: '',
      refreshing: false,
      loading : true,
      finished: false,
      page:{currentPage:1,numberPage:3,},
      orders:[],
      count:0,
      orderPlacedTime:'',
      appointmentShow:false,
      appointmentTime:new Date(),
      appointmentTimeStr:'',
      isAppointmentTimeShow:false,
      item:{},
      notClickable:false,  //预约时间防连点
    }
  },
  mounted() {
    this.checkPhoneorMobel()
    // this.getStaffFuntionModelList()
    this.finished = false;
    this.loading = true;
    this.refreshing = false
    this.orders=[]
    this.onLoad();
  },
  methods:{
    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    //下拉刷新
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.orders = []
      this.page =  {currentPage:1,numberPage:3}
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false
      this.onLoad();
      responseUtil.alertMsg(this,"刷新成功")
    },
    onLoad() {
      let that = this
      let queryData = {}
      // queryData.type='19'
      queryData.currentPage = that.page.currentPage
      queryData.numberPage = that.page.numberPage
      queryData.user_id = getStaffId()
      mainTrialPlanRecordPage(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.count = response.data.data.pageCount
          that.orderPlacedTime = response.data.data.orderPlacedTime
          let list=response.data.data.data?response.data.data.data:[]
          for (let i = 0; i < list.length; i++) {
            that.orders.push(list[i])
          }
          if(that.refreshing){
            that.refreshing = false
          }
          // 加载状态结束
          that.loading = false;
          that.page.currentPage++;
          // 数据全部加载完成
          if (that.orders.length >= that.count) {
            that.finished = true;
          }
          //判断数据是否为空，空则显示空状态图
          // if (that.orders.length == 0) {
          //   that.isEmptuyFlag = true;
          // } else {
          //   that.isEmptuyFlag = false;
          // }
        })
      })
    },
    completeTask(item){
      this.$router.push({
        name:'probationAssignmentComplete',
        query:{
          id:item.id,
        }
      });
    },
    completeView(item){
      this.$router.push({
        name:'probationAssignmentDetail',
        query:{
          id:item.id,
        }
      });
    },
    appointmentExam(item){
      this.appointmentShow = true
      this.item = item
      this.appointmentTime = new Date()
      this.appointmentTimeStr = util.dateToString(this.appointmentTime)
    },
    appointmentExamConfirm(value){
      this.appointmentTime = value
      this.appointmentTimeStr = util.dateToString(this.appointmentTime)
      this.isAppointmentTimeShow = false
    },
    saveAppointmentTime(){
      if(this.notClickable){
        return
      }
      let that = this
      let initData = {}
      initData.appointmentTime = this.appointmentTime
      initData.mainTrialplanRecord_id = this.item.id
      initData.probationQuiz_id = this.item.status==='8'?this.item.probationQuiz_id:undefined
      initData.staff_id = getStaffId()
      //已审批3/考试时间待确认5传0预约，考试不通过传3重新预约
      initData.status = this.item.status==='3'||this.item.status==='5'||this.item.status==='6'?'0':'3'
      this.notClickable = true
      saveOrUpdateAppointQuiz(initData).then(function (response){
        that.notClickable = false
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertOK(that,'预约成功')
          that.appointmentShow = false
          that.finished = false;
          that.orders = []
          that.page =  {currentPage:1,numberPage:3}
          // 重新加载数据
          // 将 loading 设置为 true，表示处于加载状态
          that.loading = true;
          that.refreshing = false
          that.onLoad();
        })
      })
    },

    orderEntry(item){
      Dialog.confirm({
        title: '友情提示',
        message: `确定申请开单入职吗？`,
      })
        .then(() => {
          if(this.notClickable){
            return
          }
          let that = this
          let initData = {}
          initData.mainTrialplanRecord_id = item.id
          initData.staff_id = getStaffId()
          //已审批3/考试时间待确认5传0预约，考试不通过传3重新预约,6开单入职
          initData.status = 6
          this.notClickable = true
          saveOrUpdateAppointQuiz(initData).then(function (response){
            that.notClickable = false
            responseUtil.dealResponse(that, response, () => {
              responseUtil.alertOK(that)
              that.finished = false;
              that.orders = []
              that.page =  {currentPage:1,numberPage:3}
              // 重新加载数据
              // 将 loading 设置为 true，表示处于加载状态
              that.loading = true;
              that.refreshing = false
              that.onLoad();
            })
          })

        })
        .catch(() => {
        });
    }
  }
}
</script>

<style scoped lang="less">
//列表
.auditDiv{
  //padding-top: 80px;
}
/*    循环列表DIV*/
.stateList{
  border-radius: 0.3rem;
  width: 93%;
  margin-left: 0.35rem;
  position: relative;
  margin-bottom: 0.4rem;
  background-color: #ffffff;
  padding-bottom: 0.5rem;
  padding-top: 0.1rem;

}
/*列表头部信息*/
.headInfo{
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 0.4rem;
  font-size: 15px;
  font-weight: bold;
}
.top-title{
  margin: 0;
  width: fit-content;
  padding: 0 5px;
  height: 20px;
  font-size: 10px;
  text-align: center;
  line-height: 22px;
  border-radius: 8px 0;
  color: white;
}
//0待提交，1待上级审， 2待上上级审，3已审批，4拒绝入职，5重新预约考试，6预约待确认，7成绩待上传，8考试未通过，9入职，10重新学习，11开单入职
.top-title-0,.top-title-10{
  background: linear-gradient(to right, #7cc7ff, #3596fd);
}
.top-title-2,.top-title-1{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-3,.top-title-9{
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-4{
  background: linear-gradient(to right, #bfcbd9, #a8b9cc);
}
.top-title-5,.top-title-8{
  background: linear-gradient(to right, #cc004b, #660026);
}
.top-title-6{
  background: linear-gradient(to right, #05C7CA, #00B0B6);
}
.top-title-7,.top-title-11{
  background: linear-gradient(to right, #990066, #ff66ff);
}
/*    分割线3*/
.rule3 {
  border: 0.01rem solid #efefef;
  width: 100%;
  height: 0rem;
  margin-top: 0.1rem;
}
/*    下部按钮*/
.btnArray{
  display: flex;
  justify-content: right;
}
.btn1,.btn2{
  /*float: right;*/
  margin-top: 0.35rem;
  margin-right: 0.7rem;
  height: 0.82rem;
  min-width: 2.3rem;
  padding: 0 0.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*按钮*/
.btn1{
  background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
  color: white;
}
.btn2{
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white,white),
  linear-gradient(to right ,#FFC274,#FF5D3B);
  background-clip: padding-box,border-box;
  background-origin: border-box;
  color: #FF5D3B;
}


.bottom-fixed{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: rgba(250, 250, 250, 1);
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
}
.bottom-fixed-orderLook{
  width: 91px;
  height: 40px;
  line-height: 43px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(to right,#7cc7ff,#3596fd);
  box-shadow: 0px 1px 1px 0px rgba(0, 115, 202, 0.5);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Semibold;
}

.date-dropDownList{
  padding: 0.3rem;
  //height: 92%;
}

.notice{
  padding: 0.1rem 0.4rem;
  font-size: 0.35rem;
  line-height: 0.6rem;
  margin-top: 0.4rem;
}
/*选择名称的div*/
.part-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 9rem;
  line-height: 1rem;
  height: 50px;
  margin: 0px auto;
  align-items: center;
  /*圆点样式---灰色*/
  .maker {
    color: #999999;
    padding-left: 14px;
    padding-right: 8px;
    font-size: 28px;
  }
  .orangeColor{
    color: rgba(255, 93, 59, 1);
  }
  .part-inputpart-row-header {
    font-weight: bold;
    font-size: 15px;
    width: 3rem;
  }
  /*竖线样式*/
  .content-divide {
    font-size: 14px;
    color: #efefef;
    margin: 0 5px 0 5px;
  }
  .part-inputpart-row-graytext {
    color: #d9d9d9;
    width: 6rem;
    font-size: 14px;
    padding-left: 0.3rem;
  }
  .part-inputpart-row-normaltext{
    color: black;
  }
  .part-inputpart-row-right-downArrow{
    width: 9px;
    height: 9px;
    margin-right: 15px;
  }
  /*选择三角图标的向上向下样式*/
  .part-inputpart-row-right-upArrow{
    width: 9px;
    height: 9px;
    /*transform: rotateX(180deg);*/
    margin-right: 15px;
  }
}
.sub_btn {
  //margin: 0.8rem auto 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.6rem;
  margin-top: 1rem;
  //position: absolute;
  //bottom: 1rem;
  //width: 94%;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  //margin: 0.1rem 0 0.3rem;

}
</style>